import React from 'react';
import styled from 'styled-components';
import Container from '../../components/container';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Common from '../../components/common';

const Dona = () => (
  <Layout>
    <SEO
      title="Quiero Ayudar"
      description="Puedes ser nuestro patrocinador permanente o donador de ocasión y para
        cualquiera de los dos casos tenemos recibos deducibles de impuestos, de
        igual manera si tienes una empresa de productos o servicio que pueden
        contribuir con nuestra causa también puedes hacerlo."
    />
    <Container>
      <Common />
      <p>
        Gracias tu tiempo de vida para ayudar a familias enteras que te
        necesitan, puedes participar con nosotros en futuros eventos en zonas
        públicas, en hospitales e incluso en los hogares de nuestros pequeños,
        para esto es necesario conocer un poquito de ti, por favor ayúdanos a
        llenar este formulario:
      </p>
      <Iframe
        src="https://amvi-mx.typeform.com/to/IxPlmy"
        title="Quiero Ayudar"
      />
    </Container>
  </Layout>
);

const Iframe = styled.iframe`
  border: 0;
  width: 100%;
  height: 450px;
`;

export default Dona;
