import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Headline from './headline';

const Common = () => (
  <>
    <Headline>Quiero ayudar</Headline>
    <p>
      Gracias por tu interés por sumarte a nuestra causa, ten por seguro que
      estás tomando una bella decisión que cambiará la vida niños con
      enfermedades crónico degenerativas y la de sus familias, te haremos
      algunas preguntas para conocerte y desarrollar el proyecto que tienes en
      mente.
    </p>
    <p>
      Nuestro lema es “No damos lo que podemos dar sino lo que queremos dar;
      amor y vida” siendo nuestro tiempo, nuestra propia vida el valor más
      preciado que podemos donar.
    </p>

    <Tabs>
      <li>
        <h2>
          <Link to="/quiero-ayudar" activeClassName="current">
            Voluntarios
          </Link>
        </h2>
      </li>
      <li>
        <h2>
          <Link to="/quiero-ayudar/dona" activeClassName="current">
            Donadores
          </Link>
        </h2>
      </li>
      <li>
        <h2>
          <Link to="/quiero-ayudar/patrocinador" activeClassName="current">
            Patrocinador
          </Link>
        </h2>
      </li>
    </Tabs>
  </>
);

export default Common;

const Tabs = styled.ul`
  list-style: none;
  padding: 0;
  margin: 3rem 0 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a.current {
    border-bottom: 5px solid #3671af;
  }
  a:not(.current) {
    opacity: 0.25;
    &:hover {
      opacity: 0.9;
    }
  }

  @media screen and (max-width: 768px) {
    h2 {
      font-size: 1.5rem;
    }
  }
`;
